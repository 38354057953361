const { default: getConfig } = require('next/config');
const {
  DEFAULT_LOCALE,
} = require('./i18n.config');
const { i18nExtractLanguageSubpath } = require('./i18n.utils');

const { publicRuntimeConfig = {} } = getConfig() || {};

const extractLanguageSubdomain = (domain = 'mate.academy') => domain.split('.')[0];

const domainLocaleDetector = {
  name: 'domain',
  lookup(req) {
    // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
    const url = typeof window !== 'undefined'
      // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
      ? window.location.href
      : req.originalUrl;

    const supportedLanguages = (
      publicRuntimeConfig.DYNAMIC_I18N_CONFIG?.languages ?? []
    );

    // force en lang for knowely (without subpath)
    if (url.includes('knowely.com')) {
      return 'en-us';
    }

    return i18nExtractLanguageSubpath({
      url,
      supportedLanguages,
    }) || DEFAULT_LOCALE;
  },
};

module.exports = {
  domainLocaleDetector,
  extractLanguageSubdomain,
};
